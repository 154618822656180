<template>
<section class="domain_content">
    <h1>
        Omar WhatsApp Introduction & Download New Version
    </h1>

    <div class="old-version-column plus-column ob-column">
        <div class="version-item">
            <div class="old-version-row">
                <div class="plus-img">
                    <img src="@/assets/ob.webp" alt="ob whatsapp logo" />
                </div>
                <div class="version-detail">
                    <h4>OB WhatsApp APK Info</h4>
                    <div class="apk-detail-wrapper">
                        <div class="apk-detail">
                            <p><strong>Version:</strong> V62</p>
                            <p><strong>Size:</strong> 82.5MB</p>
                            <p><strong>Last Updated:</strong> <br>Nov 2024</p>
                        </div>
                        <div class="apk-detail">
                            <p><strong>Vote:</strong> ⭐⭐⭐⭐</p>
                            <p><strong>Liscense:</strong> Free</p>
                            <p><strong>System:</strong> <br>Android 5+</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="big-download-btn big-download-btn top-download fix has-exp">
                <div class="small">
                    <img src="@/assets/security.webp" alt="">
                    Security Verified
                </div>
                <div class="small">
                    <img src="@/assets/verified.webp" alt="">
                    Official Certification
                </div>
            </div>
        </div>

    </div>

    <TOC :tocList="toc" :language="language" />

    <h2 id=tbc_1>
        {{toc[0]}}
    </h2>
    <p>
        OBWhatsApp is an alternative to the regular WhatsApp that enhances the user experience by offering advanced features. </p>
    <p>
        Whether you want to personalize the look of your chats or enjoy features like auto-reply and advanced privacy settings, OBWhatsApp has something for everyone.
    </p>
    <p>
        It is updated regularly to keep up with the latest user demands and security improvements, making it a popular choice among mod app enthusiasts.
    </p>
    <p>
        <img class="wi" alt="anwhatsapp info" src="@/assets/ob14-1.webp">
    </p>

    <h2 id="tbc_2">
        {{toc[1]}}
    </h2>
    <Features :features="features" :classType="classType" />

    <h2 id="tbc_3">
        {{toc[2]}}
    </h2>
    <p>
        Downloading and installing OBWhatsApp is simple, but users should make sure they get it from a trusted source to avoid malware or fake apps.
    </p>
    <p>
        OBWhatsApp comes in six different versions, including the popular OB5 version, each offering similar core features with slight modifications.
    </p>
    <p>
        Once downloaded, users need to enable installation from unknown sources in their device settings, install the APK file, and then proceed with the registration, just as with regular WhatsApp.
    </p>

    <h2 id="tbc_4">
        {{toc[3]}}
    </h2>
    <p>
        WhatsApp Messenger is more suitable for users who prioritize security and official support, whereas OBWhatsApp is perfect for users looking for more advanced features like custom themes, no forwarded labels, and better control over privacy settings.
    </p>
    <table>
        <tr>
            <th>Features</th>
            <th>WhatsApp</th>
            <th>OB WhatsApp</th>
        </tr>
        <tr>
            <td>Privacy Protection</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Send Larger Files</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>HD Files</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Anti-Deleted Messages</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Themes</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Hide Online Status</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Save Statuses</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Change Fonts</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Dual Account</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Auto-Reply Messages</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Schedule Messages</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td>Unread Messages</td>
            <td>×</td>
            <td>✔️</td>
        </tr>
    </table>

    <h2 id="tbc_5">
        {{toc[4]}}
    </h2>
    <FAQ :faqs="faqs" />

    <h2 id="tbc_6">
        {{toc[5]}}
    </h2>
    <p>
        OBWhatsApp offers a wide range of features that enhance the messaging experience beyond the capabilities of the regular WhatsApp. With advanced privacy options, customization, and a user-friendly interface, it's a great option for users seeking more from their messaging app.
    </p>

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import TOC from '@/components/TOC.vue';
import Features from '@/components/Features.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    ...{
        "metaInfo": {
            "title": "Download OBWhatsApp Latest Version for Android 2024",
            "meta": [{
                "name": "description",
                "content": "What is OBWhatsApp and why people choose to use this modified version of WhatsApp? Visit our site to explore the custom features it provides."
            }, {
                "name": "title",
                "content": "Download OBWhatsApp Latest Version for Android 2024"
            }, {
                "property": "og:title",
                "content": "Download OBWhatsApp Latest Version for Android 2024"
            }, {
                "property": "og:description",
                "content": "What is OBWhatsApp and why people choose to use this modified version of WhatsApp? Visit our site to explore the custom features it provides."
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://gbwhatsapk.chat/ob-whatsapp/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            toc: [
                "Introduction of OBWhatsApp",
                "Popular Features of OBWhatsApp",
                "Download and Install OBWhatsApp",
                "What's New in OBWhatsApp?",
                "FAQ",
                "Conclusion"
            ],
            features: [{
                    title: 'Airplane Mode',
                    text: 'With OBWhatsApp\'s Airplane Mode, you can completely disconnect from the internet within the app while keeping your phone\'s general internet connection active.'
                },
                {
                    title: 'No Forwarded Labels',
                    text: 'Unlike the official WhatsApp, OBWhatsApp allows you to forward messages without the "forwarded" label, helping users share content freely without indicating it was forwarded.'
                },
                {
                    title: 'Auto-Reply',
                    text: 'OBWhatsApp offers a convenient auto-reply function, where users can set custom responses to automatically reply to messages when they are busy or unavailable.'
                },
                {
                    title: 'Anti-Ban Protection',
                    text: 'OBWhatsApp enhances anti-ban protection, reducing the chances of your account being restricted when using modified apps.'
                },
                {
                    title: 'Custom Themes',
                    text: 'OBWhatsApp allows users to choose from a variety of custom themes, enabling them to personalize their chat interface.'
                },
                {
                    title: 'Language Support',
                    text: 'OBWhatsApp includes multi-language support, allowing users to select their preferred language for a more localized experience.'
                },
                {
                    title: 'Widget Setting',
                    text: 'Users can customize widgets in OBWhatsApp to quickly access chats or other app features from their home screen, making it more convenient to read messages.'
                },
                {
                    title: 'Hide Blue Ticks',
                    text: 'OBWhatsApp includes the option to hide blue ticks, allowing users to maintain privacy by not indicating that a message has been read.'
                }
            ],
            classType: 'ob',
            faqs: [{
                    "question": "Can I use OB WhatsApp on iPhone or PC?",
                    "answer": "OBWhatsApp was originally developed for Android users. There is no version for iOS or PC. If you need to use the PC version, you can download an emulator or mirror your phone screen to PC."
                },
                {
                    "question": "Is it safe to use OBWhatsApp?",
                    "answer": "It's not 100% secure. The developers designed the app based on WhatsApp, but users need to log in via the official account. The WhatsApp team is trying to ban accounts on WhatsApp Mod apps."
                },
                {
                    "question": "How to transfer WhatsApp data to OBWhatsApp?",
                    "answer": "Simply log in to WhatsApp and verify your account. Then, the data under this account will be restored."
                },
                {
                    "question": "What is Omar WhatsApp?",
                    "answer": "It's the full name of OBWhatsApp. They are the same app."
                },
                {
                    "question": "Why can't I install OBWhatsApp on Android?",
                    "answer": "If you can't install OBWhatsApp successfully, we suggest installing the official WhatsApp. Or, you can try some alternatives like GB WhatsApp, WhatsApp Plus, Yo WhatsApp, FM WhatsApp, etc."
                }
            ]
        };
    },
    components: {
        TOC,
        Features,
        FAQ
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },

        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },

        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
